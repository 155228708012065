import React from 'react';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import {TopicsContextProvider} from "./components/Context/TopicsContext/TopicsContextProvider";
import {SourcesContextProvider} from "./components/Context/SourcesContext/SourcesContextProvider";
import {UserContextProvider} from "./components/Context/UserContext/UserContextProvider";
import {FeedContextProvider} from "./components/Context/FeedIdContext/FeedContextProvider";
import {AppRouter} from "./AppRouter";

const App: React.FC = () => {
    return (
        <GoogleReCaptchaProvider reCaptchaKey="6LczIaApAAAAAJJ-Cy0R-lpKAB-Jmp-kY8T18BfD">
            <TopicsContextProvider>
                <SourcesContextProvider>
                    <UserContextProvider>
                        <FeedContextProvider>
                            <AppRouter />
                        </FeedContextProvider>
                    </UserContextProvider>
                </SourcesContextProvider>
            </TopicsContextProvider>
        </GoogleReCaptchaProvider>
    );
};

export default App;
