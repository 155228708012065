// In TopicsContextProvider.tsx
import React, {useState} from "react";
import {TopicsContext} from "./TopicsContext";
import {TopicsEntity} from "./TopicsEntity";

export const TopicsContextProvider = ({ children }) => {
    const [topics, setTopics] = useState<TopicsEntity[]>([]);

    return (
        <TopicsContext.Provider value={[topics, setTopics]}>
            {children}
        </TopicsContext.Provider>
    );
};
