import { User } from "firebase/auth";

export const FetchData = async (input: RequestInfo, init?: RequestInit, user?: User): Promise<Response> => {
    if (user) {
        const token = await user.getIdToken();
        if (init?.headers) {
            init.headers['Authorization'] = `Bearer ${token}`;
        } else {
            init!.headers = {
                'Authorization': `Bearer ${token}`
            };
        }
    }
    const defaultHeaders = {
        'Content-Type': 'application/json',
    };

    const settings: RequestInit = {
        ...init,
        headers: {
            ...defaultHeaders,
            ...init?.headers,
        },
    };

    const response = await fetch(input, settings);

    if (!response.ok) {
        console.log(response.statusText);
        throw new Error('Network response was not ok: ' + response.statusText);
    }

    return response;
}
