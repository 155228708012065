import {Menu} from "antd";
import React from "react";
import {FeedEntity} from "../../Context/FeedIdContext/FeedEntity";

export interface FeedsMenuProps {
    setSelectedFeed: (feed: FeedEntity) => void;
    selectedFeed: FeedEntity;
    feeds: FeedEntity[];
}

export const FeedsMenu: React.FC<FeedsMenuProps> = (props) => {

    const selectFeed = async (item) => {
        props.setSelectedFeed(item);
        console.log('selected', item);
    }


    return (
        <Menu
            mode="inline"
            selectedKeys={[props.selectedFeed.feedName]}
            style={{borderRight: 0}}
        >
            {
                props.feeds.map(item => (
                    <Menu.Item
                        key={item.feedName}
                        onClick={() => selectFeed(item)}
                    >
                                <span style={{color: item.unreads > 0 ? 'black' : 'grey'}}>
                                    {item.unreads > 0 ? <strong># {item.feedName}</strong> : "# " + item.feedName}
                                </span>
                    </Menu.Item>
                ))
            }
        </Menu>
    );
}
