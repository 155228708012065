import { UserContext } from "./UserContext";
import {useState} from "react";
import { app }   from "../../../infrastructure/firebase/Firebase";
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import {message} from "antd";

export const UserContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const auth = getAuth(app);

    const login = async (email, password) => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            message.success('Login successful!');
        } catch (error) {
            console.error(error);
            alert(error.message);
        }
    };

    const signup = async (email, password) => {
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            message.success('Registration successful!');
        } catch (error) {
            console.error(error);
            alert(error.message);
        }
    };

    return (
        <UserContext.Provider value={[user, setUser, login, signup]}>
            {children}
        </UserContext.Provider>
    );
}
