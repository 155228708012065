import {useContext, useEffect} from "react";
import {UrlEnum} from "../../../enums/Url.enum";
import {TopicsEntity} from "../../Context/TopicsContext/TopicsEntity";
import {TopicsContext} from "../../Context/TopicsContext/TopicsContext";
import {FetchData} from "../../../shared/FetchData";

export const LoadTopics = () => {
    const [topics, setTopics] = useContext(TopicsContext)



    useEffect(() => {
        const loadTopicsFromLocalStorage = () => {
            const localTopics = localStorage.getItem('topics');
            if (localTopics && JSON.parse(localTopics).length > 0) {
                setTopics(JSON.parse(localTopics));
                console.log('Topics from local storage:', JSON.parse(localTopics));
                // invalide local storage after 1 hour
                const oneHour = 60 * 60 * 1000;
                const currentTime = new Date().getTime();
                const topicsTime = localStorage.getItem('topicsTime');

                if (!topicsTime || isNaN(Number(topicsTime))) {
                    localStorage.removeItem('topics');
                    localStorage.removeItem('topicsTime');
                    return;
                }

                if (topicsTime && currentTime - parseInt(topicsTime) > oneHour) {
                    localStorage.removeItem('topics');
                    localStorage.removeItem('topicsTime');
                }
            } else {
                fetchTopicsFromAPI();
            }
        };

        const fetchTopicsFromAPI = async () => {
            const response = await FetchData(`${UrlEnum.SERVER_URL}${UrlEnum.TOPICS}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            const parsedData = data.map((item: any) => new TopicsEntity(
                item.topicId,
                item.topicName,
                0,
            ));

            setTopics(parsedData);
            localStorage.setItem('topics', JSON.stringify(parsedData));
        };

        if (topics.length === 0) {
            loadTopicsFromLocalStorage();
        }
    }, [topics, setTopics]);

    return topics;
}
