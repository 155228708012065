import React from "react";
import { SidebarLayout, SidebarLayoutProps } from "../../Layout/UI/SidebarLayout";
import './AboutComponent.css'; // Import your CSS file

export const AboutComponent: React.FC = () => {
    const AboutContent: React.ReactNode = (
        <div className="about-content">
            <h1>People like us, do things we do</h1>
            <p>
                We are a group of individuals who are passionate about technology and its impact on society. We believe that technology should be used to empower individuals and promote freedom, privacy, and autonomy. Our application is designed to provide users with the tools they need to take control of their online presence and protect their data from manipulation and censorship.
            </p>
            <h2>Problems We Aim to Address</h2>
            <ul>
                <li>
                    Manipulation - <a href="https://x.com/tsarnick/status/1798471323024502806">"Programming" people</a>
                </li>
                <li>
                    Censorship - <a href="https://x.com/reddit_lies/status/1803783233827479863">Reddit lies</a>
                </li>
                <li>
                    Moderation - <a href="https://apnews.com/article/meta-platforms-mark-zuckerberg-biden-facebook-covid19-463ac6e125b0d004b16c7943633673fc">White House pressured Facebook over some COVID-19 content during pandemic</a>
                </li>
                <li>
                    Privacy - <a href="https://www.wired.com/story/google-chrome-incognito-mode-data-deletion-settlement/">Incognito Mode Myth</a>
                </li>
            </ul>
            <h2>Why Should Anyone Dictate What You Read or Watch?</h2>
            <p>
                As corporate-controlled social media platforms and tools continue to dominate, people are increasingly aware of the negative impacts these platforms have on free will, primarily due to the opaque and manipulative nature of their algorithms.
                These algorithms are <b>designed to maximize user engagement and profit</b>, often at the expense of user privacy and autonomy. They manipulate user behavior, control the flow of information, and amplify certain voices over others.
            </p>
            <h2>Our Solution</h2>
            <p>
                To combat this, our application empowers you to take full control of your online data and identity, ensuring that your personal information stays in your hands, not in the hands of big corporations. You decide who can access your data, giving you the freedom to share only what you want, with whom you want. No more being forced into one-size-fits-all platforms—create your own digital space tailored to your needs. This ensures greater privacy, security, and autonomy.
            </p>
            <p>
                Unlike other privacy tools that still rely on pre-built, often opaque algorithms, our application lets you take control by choosing or even creating algorithms that align with your values and needs. This level of customization is unprecedented, offering you a truly personalized online experience. <br />
                Instead of being subjected to the biases and manipulations of corporate algorithms, users are given the choice to select or build their own algorithms. <br />
                This flexibility allows users to customize their content discovery and interaction experiences according to their personal preferences and trust levels. They can switch between algorithms or opt out altogether, becoming their own discovery mechanisms.
            </p>
            <p>
                The core mission of our application is to create a marketplace of algorithms where users can choose which algorithms to use based on their own criteria, fostering a transparent and user-driven environment. By doing so, it empowers individuals with the freedom to control their online presence, enhancing their free agency and reducing dependence on large, centralized corporations that thrive on manipulating user data and behavior. <br />
                Our marketplace of algorithms is not just a list of options—it’s a dynamic ecosystem where users can explore, share, and even monetize their custom algorithms, creating a community-driven alternative to corporate-controlled platforms.
            </p>
            <h2>Open Source</h2>
            <p>
                Wanna join our mission? <br />
                We invite developers, privacy advocates, and anyone passionate about digital freedom to join us in shaping the future of online privacy. Contribute to our open-source project on GitLab, where you can collaborate, share ideas, and be part of a movement that prioritizes user empowerment. <br /> <a href="https://gitlab.com/mysites2/ainews">https://gitlab.com/mysites2/ainews</a>.
            </p>
        </div>
    );

    const sidebarLayoutProps: SidebarLayoutProps = {
        setSelectedFeed: () => {},
        selectedFeed: null,
        feeds: [],
        showFeedsSider: false,
        children: AboutContent
    };

    return (
        <SidebarLayout {...sidebarLayoutProps} />
    );
}
