import {ChatAdapter, StreamingAdapterObserver} from '@nlux/core';
import { CreateWsConnection } from "./CreateWsConnection";
import { UrlEnum } from "../../../enums/Url.enum";

type Message = {
    role: string;
    content: string;
    images: any[];
};

interface ChatResponse {
    model: string;
    created_at: string;
    message: Message;
    done: boolean;
    done_reason: string | null;
    total_duration: number;
    load_duration: number;
    prompt_eval_count: number;
    prompt_eval_duration: number;
    eval_count: number;
    eval_duration: number;
}

type ChatRequest = {
    userId: string;
    connectionId: string;
    model: string;
    messages: Message[];
    options: any;
};



const sendMessage = async (userMessage: string, wsAdapter: CreateWsConnection) => {

        // Add the user's message to allMessages only once at the end
        // setAllMessages(prevMessages => [...prevMessages, newMessageObj]);

        const chatRequest: ChatRequest = {
            userId: wsAdapter.GetWsConnectionId(),
            connectionId: wsAdapter.GetWsConnectionId(),
            model: "default-model",
            messages: [
                {
                    role: "user",
                    content: userMessage,
                    images: []
                },
                ],
            options: {}
        };
        console.log("SendMessage request: ", chatRequest);
    try {
        const response = await fetch(`${UrlEnum.SERVER_URL}${UrlEnum.STREAMING_CHAT_WITH_TRENDS}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(chatRequest),
        });

        if (!response.ok) {
            console.log("Response: ", response);
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();
        console.log("SendMessage response: ", data);
    } catch (error) {
        console.error("Failed to send message: ", error);
    }

};

export const myCustomAdapter: ChatAdapter = {
    streamText: async (message: string, observer: StreamingAdapterObserver): Promise<void> => {
        try {
        const wsAdapter =  CreateWsConnection.getInstance();
        const connection = wsAdapter.GetWsConnection();
            connection.on('ReceiveMessage', (message: any) => {
                try {
                    console.log("Received message from server: ", message);
                    if (typeof message === 'string') {
                        const jsonMessage: ChatResponse = JSON.parse(message);
                        observer.next(jsonMessage.message.content);
                        if (jsonMessage.done === true) {
                            observer.complete();
                        }
                    } else if (typeof message === 'object') {
                        // Handle case where message is already an object
                        observer.next(message.message.content);
                        if (message.done === true) {
                            observer.complete();
                        }
                    } else {
                        console.error("Received message is not a valid JSON string or object:", message);
                    }
                } catch (error) {
                    console.error("Failed to parse message: ", error);
                    console.log("Error message: ", JSON.stringify(message));
                    observer.error(error);
                }
            });

        await sendMessage(message,wsAdapter);
    } catch (error) {
        console.error("Failed to stream text: ", error);
        }

    },

};
