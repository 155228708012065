import {ColumnEntity} from "../../Column/Domain/Entities/ColumnEntity";

export class FeedEntity {
    feedId: number;
    feedName: string;
    columnEntities: ColumnEntity[];
    unreads: number;

    constructor(feedId: number, feedName: string, columnEntities: ColumnEntity[]) {
        this.feedId = feedId;
        this.feedName = feedName;
        this.columnEntities = columnEntities;
        this.unreads = 0; // TODO: Number of unread articles in this feed
    }

    public addColumn(column: ColumnEntity) {
        this.columnEntities.push(column);
    }

    removeColumn(columnIndex: number) {
        this.columnEntities = this.columnEntities.filter(column => column.columnIndex !== columnIndex);
    }
}
