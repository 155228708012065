export const UrlEnum = {
    SERVER_URL: process.env.NODE_ENV === 'production' ? 'https://semantic-kernel.bauerdavid.com' : 'http://localhost:5006',
    WEBSOCKER_SERVER_URL: process.env.NODE_ENV === 'production' ? 'ws://semantic-kernel.bauerdavid.com:3000' : 'ws://localhost:5006',
    AINEWS: '/api/ainews/news',
    TOPICS: '/api/ainews/topics',
    NEWEST_ARTICLES: '/api/ainews/newest-articles',
    ARTICLES: '/api/ainews/articles-from-elastic',
    TOPICS_FROM_TEXT: '/api/ainews/topics-from-text',

    FEEDS: '/api/ainews/feeds',
    STREAMING_CHAT_WITH_TRENDS: '/api/summary/api/chat',

    UPVOTE: '/upvote',
    DOWNVOTE: '/downvote',
    CHAT: '/chatHub',
};
