import React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import User = firebase.User;

type UserContextType = [
        User | null,
    React.Dispatch<React.SetStateAction<User | null>>,
    (email: string, password: string) => Promise<void>,
    (email: string, password: string) => Promise<void>
];

export const UserContext = React.createContext<UserContextType>(null);
