import React, { useState } from "react";
import { Layout, Menu, Modal } from 'antd';
import { HomeOutlined, LoginOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import LoginForm from '../../Login/UI/LoginForm';
import SignupForm from '../../SignUp/UI/SignUpForm';
import { FeedsMenu } from "../../Feeds/UI/FeedsMenu";
import {FeedEntity} from "../../Context/FeedIdContext/FeedEntity";
import { AiFillDiscord } from "react-icons/ai";
import {IoChatbubblesSharp} from "react-icons/io5";



export interface SidebarLayoutProps {
    setSelectedFeed: (feed: FeedEntity) => void;
    selectedFeed: FeedEntity;
    feeds: FeedEntity[];
    showFeedsSider: boolean;
    children: React.ReactNode;
}

const { Sider } = Layout;

export const SidebarLayout: React.FC<SidebarLayoutProps> = (props: SidebarLayoutProps)  => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [formType, setFormType] = useState('login');
    const navigate = useNavigate();

    const showLoginModal = (type) => {
        setFormType(type);
        setIsModalVisible(true);
    };

    const handleLoginOk = () => {
        setIsModalVisible(false);
    };

    const handleLoginCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <Layout>
            <Modal title={formType === 'login' ? 'Login' : 'Sign Up'} visible={isModalVisible} onOk={handleLoginOk}
                   onCancel={handleLoginCancel}>
                {formType === 'login' ? <LoginForm /> : <SignupForm />}
            </Modal>
            <Sider trigger={null} collapsible collapsed={true} theme={"light"} style={{position: 'sticky', top: 0,height: '100vh'}}>
                <div className="demo-logo-vertical" />
                <Menu
                    theme="light"
                    mode="inline"
                    defaultSelectedKeys={['1']}
                    items={[
                        {
                            key: '2',
                            icon: <HomeOutlined />,
                            label: 'Home',
                            onClick: () => {
                                navigate('/');
                            }
                        },
                        {
                            key: '5',
                            icon: <AiFillDiscord />,
                            label: 'Discord',
                            onClick: () => {
                                window.open('https://discord.gg/xsfR2CxAxz', '_blank');
                            }
                        },
                        {
                            key: '6',
                            icon: <LoginOutlined />,
                            label: 'Login/Sign up',
                            onClick: () => {
                                showLoginModal('login');
                            }
                        },
                        {
                            key: '7',
                            icon: <FontAwesomeIcon icon={faCoffee} />,
                            label: 'Buy Me A Coffee',
                            onClick: () => {
                                window.open('https://www.buymeacoffee.com/warezak_', '_blank');
                            }
                        },
                        {
                            key: '8',
                            icon: <IoChatbubblesSharp />,
                            label: "let's chat with data",
                            onClick: () => {
                                navigate('/trends');
                            },
                        },
                        {
                            key: '9',
                            icon: <QuestionCircleOutlined />,
                            label: 'About Project',
                            onClick: () => {
                                navigate('/about');
                            },
                        },
                    ]}
                />
            </Sider>
            {props.showFeedsSider && (
            <Sider theme={"light"} style={{ padding: '16px', position: 'sticky', top: 0, height: '100vh' }}>
                <FeedsMenu setSelectedFeed={props.setSelectedFeed} selectedFeed={props.selectedFeed} feeds={props.feeds} />
            </Sider>
                )}
            <Layout>
                {props.children}
            </Layout>
        </Layout>
    );
};
