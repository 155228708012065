import React, {useState} from "react";
import {SourceEntity} from "./SourceEntity";
import {SourcesContext} from "./SourcesContext";

export const SourcesContextProvider = ({ children }) => {
    const [sources, setSources] = useState<SourceEntity[]>([]);

    return (
        <SourcesContext.Provider value={[sources, setSources]}>
            {children}
        </SourcesContext.Provider>
    );
}
