import React, {useState} from 'react';
import {Box} from '@mui/material';
import {ResponseTopics} from "../Context/ResponseTopics";
import { ArticleCard } from './ArticleCard';

interface Props {
    aiNews?: ResponseTopics[];
}

const NewsList: React.FC<Props> = (props) => {
    const [readArticles, setReadArticles] = useState<number[]>([]);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleMouseOver = (index: number) => {
        if (!readArticles.includes(index)) {
            setReadArticles((prevReadArticles) => [...prevReadArticles, index]);
        }
    };

    return (
        <Box>
            {props.aiNews && props.aiNews.map((article, index) => (
                <ArticleCard
                    key={index}
                    article={article}
                    index={index}
                    readArticles={readArticles}
                    handleMouseOver={handleMouseOver}
                    handleMenuOpen={handleMenuOpen}
                    handleMenuClose={handleMenuClose}
                    anchorEl={anchorEl}
                />
            ))}
        </Box>
    );
};

export default NewsList;
