import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {LayoutComponent} from "./components/Layout/UI/LayoutComponent";
import {AboutComponent} from "./components/About/UI/AboutComponent";
import {TrendsComponent} from "./components/Trends/UI/TrendsComponent";

export const AppRouter: React.FC = () => {
    return (
        <Router>
            <Routes>
                <Route path="/" Component={LayoutComponent}/>
                <Route path="/about" Component={AboutComponent} />
                <Route path="/trends" Component={TrendsComponent} />
            </Routes>
        </Router>
    );
};
