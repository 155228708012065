export class ResponseTopics {
    id: number;
    title: string;
    content: string;
    imageUrl: string;
    articleUrl: string;
    sources: string[]; // twitter, reddit, people from twitter
    publishDate: string;
    articleTopics: string[];
    customData: any;

    constructor(id: number, title: string, content: string, imageUrl: string, sources: string[], articleUrl: string, publishDate: string, articleTopics: string[], customData: any) {
        this.id = id;
        this.title = title;
        this.content = content;
        this.imageUrl = imageUrl;
        this.articleUrl = articleUrl;
        this.sources = sources;
        this.publishDate = publishDate;
        this.articleTopics = articleTopics;
        this.customData = customData;
    }
}
