import { HttpTransportType, HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { UrlEnum } from "../../../enums/Url.enum";

interface ConnectionIdMessage {
    connectionId: string;
}

export class CreateWsConnection {
    private static instance: CreateWsConnection | null = null;
    private wsConnection: HubConnection | null = null;
    private connectionId: string = "";

    private constructor() {}

    public static getInstance(): CreateWsConnection {
        if (!CreateWsConnection.instance) {
            CreateWsConnection.instance = new CreateWsConnection();
        }
        return CreateWsConnection.instance;
    }

    private buildWsConnection() {
        const connection = new HubConnectionBuilder()
            .configureLogging(LogLevel.Debug)
            .withUrl(`${UrlEnum.SERVER_URL}${UrlEnum.CHAT}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets
            })
            .withAutomaticReconnect()
            .build();

        connection.keepAliveIntervalInMilliseconds = 1000;

        this.setConectionId(connection);

        console.log('Start Connecting to WebSocket');
        connection.start().then(async () => {
            console.log('Connected to WebSocket');
            connection.invoke("GetConnectionId").catch(err => console.error(err.toString()));
        });

        return connection;
    }

    public GetWsConnection(): HubConnection {
        if (!this.wsConnection) {
            this.wsConnection = this.buildWsConnection();
        }
        return this.wsConnection;
    }

    public GetWsConnectionId(): string {
        return this.connectionId;
    }

    private setConectionId(connection: HubConnection) {
        connection.on("ReceiveConnectionId", (message: string) => {
            const parsedConnectionId: ConnectionIdMessage = JSON.parse(message);
            console.log("Received connection ID: ", parsedConnectionId.connectionId);
            this.connectionId = (parsedConnectionId.connectionId);
        });
    }
}
