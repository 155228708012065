import React from 'react';
import {MDBCard, MDBCardBody, MDBCardLink, MDBCardText, MDBCardImage} from 'mdb-react-ui-kit';
import {Box, Typography, Stack} from '@mui/material';
import {ResponseTopics} from "../Context/ResponseTopics";
import {Tweet} from 'react-tweet';

import {Dropdown, Menu, Button} from 'antd';
import {DownOutlined} from '@ant-design/icons';

interface ArticleCardProps {
    article: ResponseTopics;
    index: number;
    readArticles: number[];
    handleMouseOver: (index: number) => void;
    handleMenuOpen: (event: React.MouseEvent<HTMLElement>) => void;
    handleMenuClose: () => void;
    anchorEl: null | HTMLElement;
}

export const ArticleCard: React.FC<ArticleCardProps> = ({
                                                            article,
                                                            index,
                                                            readArticles,
                                                            handleMouseOver,
                                                            handleMenuOpen,
                                                            handleMenuClose,
                                                            anchorEl
                                                        }) => {
    const menu = (
        <Menu>
            <Menu.Item key="0">
                {article.id}
            </Menu.Item>
        </Menu>
    );


    if (article.articleUrl.includes('twitter')) {
        return (
            <MDBCard id={`article-${index}`} key={index} style={{margin: '10px 0'}}
                     onMouseOver={() => handleMouseOver(index)}>
                <MDBCardBody>
                    <MDBCardText>
                        <Stack direction={"row"}>
                            <div className="light">
                                <Tweet id={article.customData.tweet_id}/>
                            </div>
                            <Stack direction={"row"} alignItems="flex-start">
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button>
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            </Stack>

                        </Stack>
                    </MDBCardText>

                    <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Stack direction={"row"} alignItems="flex-end" spacing={2}>
                            <strong>{new Date(article.publishDate).toLocaleString('en-GB', {
                                year: 'numeric',
                                month: '2-digit',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}</strong>
                            <Stack direction={"row"} alignItems="center">
                                Read: <input type="checkbox" checked={readArticles.includes(index)} readOnly/>
                            </Stack>
                        </Stack>
                    </Box>
                </MDBCardBody>
            </MDBCard>
        );
    }

    return (
        <MDBCard id={`article-${index}`} key={index} style={{margin: '10px 0'}}
                 onMouseOver={() => handleMouseOver(index)}>
            <MDBCardBody>
                <MDBCardText>
                    <Box>
                        <Stack direction={"row"} justifyContent="space-between" alignItems="center">
                            <MDBCardLink href={article.articleUrl} style={{
                                fontSize: '20px',
                                fontWeight: 'bold'
                            }}>{article.title}</MDBCardLink>
                            <Stack direction={"row"} alignItems="center">
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button>
                                        <DownOutlined/>
                                    </Button>
                                </Dropdown>
                            </Stack>
                        </Stack>
                        <Typography variant="body1">{article.content}</Typography>
                        <MDBCardLink href={article.articleUrl}>
                            <MDBCardImage src={article.imageUrl} alt={article.title} position="top"/>
                        </MDBCardLink>
                    </Box>
                </MDBCardText>
                <Box sx={{mt: 2, display: 'flex', justifyContent: 'flex-end'}}>
                    <Stack direction={"row"} alignItems="flex-end" spacing={2}>
                        <strong>{new Date(article.publishDate).toLocaleString('en-GB', {
                            year: 'numeric',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                            second: '2-digit'
                        })}</strong>
                        <Stack direction={"row"} alignItems="center">
                            Read: <input type="checkbox" checked={readArticles.includes(index)} readOnly/>
                        </Stack>
                    </Stack>
                </Box>
            </MDBCardBody>
        </MDBCard>
    );
};
