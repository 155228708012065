import React, { useContext, useEffect, useState } from "react";
import { TopicsEntity } from "../../Context/TopicsContext/TopicsEntity";
import { Col, Select } from "antd";
import NewsList from "../../NewsList/NewsList";
import { TopicsContext } from "../../Context/TopicsContext/TopicsContext";
import { UrlEnum } from "../../../enums/Url.enum";
import { ResponseTopics } from "../../Context/ResponseTopics";
import { FetchData } from "../../../shared/FetchData";
import { ColumnEntity } from "../Domain/Entities/ColumnEntity";
import { FeedEntity } from "../../Context/FeedIdContext/FeedEntity";
import moment from 'moment-timezone';

interface ColumnProps {
    index: number;
    feedEntity: FeedEntity; // FeedEntity that belongs to this column
    columnEntity: ColumnEntity; // FeedEntity that belongs to this column
    widthPercentage: number;
    onRemove: () => void;
}

export const ColumnComponent: React.FC<ColumnProps> = (props) => {
    const [topics] = useContext(TopicsContext);
    const [responseTopics, setResponseTopics] = useState<ResponseTopics[]>(props.columnEntity.ResponseTopics);
    const [selectedTopics, setSelectedTopics] = useState<TopicsEntity[]>(props.columnEntity.topics);

    useEffect(() => {
        const fetchAiNews = async (selectedTopics: TopicsEntity[]) => {
            const urlQueryParams = new URLSearchParams();
            selectedTopics.forEach(topic => urlQueryParams.append('topics', topic.topicName));
            const response = await FetchData(`${UrlEnum.SERVER_URL}${UrlEnum.ARTICLES}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    field: [1], //search only in topics
                    searchValue: selectedTopics.map(topic => topic.topicName),
                    pageSize: 50,
                    pageIndex: 1,
                    FromDateTime: moment().utc().subtract(1, 'days').format(),
                    ToDateTime: moment().utc().add(1, 'day').format(),

                })
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            const parsedData = data.map((item: any) => new ResponseTopics(
                item.id,
                item.title,
                item.content,
                item.imageUrl,
                item.source,
                item.articleUrl,
                item.publishDate,
                item.articleTopics,
                item.customData
            ));
            console.log(data, "data from fetch");
            setResponseTopics(parsedData);
        };

        if (selectedTopics.length > 0) {
            fetchAiNews(selectedTopics);
        }

    }, [props.columnEntity, selectedTopics]);

    useEffect(() => {
        setSelectedTopics(props.columnEntity.topics);
        setResponseTopics(props.columnEntity.ResponseTopics);
    }, [props.columnEntity]);

    const handleChange = (value: number[]) => {
        const newSelectedTopics = topics.filter(topic => value.includes(topic.topicId));
        localStorage.setItem(`feed-${props.feedEntity.feedId}_column-${props.columnEntity.columnIndex}`, JSON.stringify(newSelectedTopics));

        props.columnEntity.topics = newSelectedTopics;
        setSelectedTopics(newSelectedTopics);
    };


    return (
        <Col flex="auto" style={{ maxWidth: '700px', minWidth: '200px' }}>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <Select
                    mode="multiple"
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Please select topics"
                    value={selectedTopics.map(topic => topic.topicId)}
                    onChange={handleChange}
                    options={topics.map(topic => ({ label: topic.topicName, value: topic.topicId }))}
                    showSearch={true}
                    optionFilterProp="label"
                />
                {/*<Button type="primary" danger onClick={props.onRemove}>X</Button>*/}
            </div>
            <NewsList aiNews={responseTopics} />
        </Col>
    );
}
