import React, { useContext, useEffect, useState } from "react";
import { Layout } from 'antd';
import { ColumnComponent } from "../../Column/UI/ColumnComponent";
import { LoadTopics } from "../../Topics/Infrastructure/LoadTopics";
import { FeedContext } from "../../Context/FeedIdContext/FeedContext";
import { FeedEntity } from "../../Context/FeedIdContext/FeedEntity";
import { LoadFeeds } from "../../Topics/Infrastructure/LoadFeeds";
import {SidebarLayout, SidebarLayoutProps} from "./SidebarLayout";

const { Content } = Layout;

export const LayoutComponent: React.FC = () => {
    const [feedEntities, setFeedEntities] = useContext(FeedContext);
    const [selectedFeed, setSelectedFeed] = useState<FeedEntity>(feedEntities[0]);

    LoadFeeds({ setSelectedFeed });
    LoadTopics();


    useEffect(() => {
        if (feedEntities.length > 0) {
            setSelectedFeed(feedEntities[0]);
        }
    }, [feedEntities]);


    const removeColumn = (feedEntity: FeedEntity, columnIndex: number) => {
        setFeedEntities(prevFeeds => prevFeeds.map(feed => {
            if (feed.feedId === feedEntity.feedId) {
                const updatedColumns = feed.columnEntities.filter(column => column.columnIndex !== columnIndex);
                return new FeedEntity(feed.feedId, feed.feedName, updatedColumns);
            }
            return feed;
        }));
    };

    const sidebarLayoutProps: SidebarLayoutProps = {
        setSelectedFeed: setSelectedFeed,
        selectedFeed: selectedFeed,
        feeds: feedEntities,
        showFeedsSider: true,
        children: (
            <Content
                style={{
                    margin: '24px 5px',
                    padding: 24,
                    minHeight: 280,
                }}
            >
                <div style={{ display: 'flex' }}>
                    {selectedFeed?.columnEntities?.map((column, index) => (
                        <div key={column.columnIndex} style={{ marginRight: '20px' }}>
                            <ColumnComponent index={index} widthPercentage={100} feedEntity={selectedFeed}
                                             columnEntity={column}
                                             onRemove={() => removeColumn(selectedFeed, index)} />
                        </div>
                    ))}
                </div>
            </Content>
        )
    }

    return (
        <SidebarLayout {...sidebarLayoutProps} />
    );
}
