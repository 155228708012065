export class TopicsEntity {
    topicId: number;
    topicName: string;
    articleCount: number;

    constructor(topicId: number, topicName: string, articleCount: number) {
        this.topicId = topicId;
        this.topicName = topicName;
        this.articleCount = articleCount;
    }
}
