import React, { useEffect } from 'react';
import { SidebarLayout, SidebarLayoutProps } from "../../Layout/UI/SidebarLayout";
import {AiChat, useAiChatApi, useAsStreamAdapter} from '@nlux/react';
import { myCustomAdapter } from "../Infrastructure/ChatCustomAdapter";
import '@nlux/themes/nova.css';
import { CreateWsConnection } from "../Infrastructure/CreateWsConnection";
import './TrendsComponent.css';

export const TrendsComponent: React.FC = () => {
    const api = useAiChatApi();
    const adapter = useAsStreamAdapter(myCustomAdapter.streamText, []);

    const trendsContent: React.ReactNode = (
        <div className="trends-content">
            <div>
                <AiChat conversationOptions={{
                    conversationStarters: [
                        {
                           label: "Software Engineer trends",
                           prompt: "I'm software engineer interested in Technology and stocks, what are most interesting trends today? Use bullet points pick, at least 10 most interesting topics for me, add links to sources. {data}",
                        },
                        {
                            label: "Stock Market trader",
                            prompt: "I'm a stock market trader, what are the most interesting trends today? {data}",
                        },
                        {
                            label: "What are the most interesting trends today?",
                            prompt: "What are the most interesting trends today? {data}",
                        }
                    ]

                }} displayOptions={{ colorScheme: 'light' }} adapter={adapter} api={api} />
            </div>
        </div>
    );

    useEffect(() => {
        const wsAdapter = CreateWsConnection.getInstance();
        wsAdapter.GetWsConnection();
    }, []);

    const sidebarLayoutProps: SidebarLayoutProps = {
        setSelectedFeed: () => { },
        selectedFeed: null,
        feeds: [],
        showFeedsSider: false,
        children: trendsContent
    };

    return (
        <SidebarLayout {...sidebarLayoutProps} />
    );
};
