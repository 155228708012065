import React, { useState } from 'react';
import { FeedEntity } from './FeedEntity';
import { FeedContext } from './FeedContext';

export const FeedContextProvider = ({ children }) => {
    const [feed, setFeed] = useState<FeedEntity[]>([
        new FeedEntity(1, 'Feed 1', []),
    ]);

    return (
        <FeedContext.Provider value={[feed, setFeed]}>
            {children}
        </FeedContext.Provider>
    );
};
