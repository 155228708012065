import { useContext, useEffect, useRef } from "react";
import { FeedContext } from "../../Context/FeedIdContext/FeedContext";
import { FetchData } from "../../../shared/FetchData";
import { UrlEnum } from "../../../enums/Url.enum";
import { FeedEntity } from "../../Context/FeedIdContext/FeedEntity";

export interface LoadFeedsProps {
    setSelectedFeed: (feed: FeedEntity) => void;
}

export const LoadFeeds = (props: LoadFeedsProps) => {
    const [feeds, setFeeds] = useContext(FeedContext);
    const lastInvocationTime = useRef(0);
    const throttleLimit = 10000; // 10 seconds in milliseconds

    useEffect(() => {
        const fetchFeeds = async () => {
            const currentTime = new Date().getTime();
            if (currentTime - lastInvocationTime.current < throttleLimit) {
                return;
            }
            lastInvocationTime.current = currentTime;

            const response = await FetchData(`${UrlEnum.SERVER_URL}${UrlEnum.FEEDS}`);
            const feedsData = await response.json();

            // Ensure each feed is properly instantiated as a FeedEntity
            const feedEntities = feedsData.map(feed => new FeedEntity(feed.feedId, feed.feedName, feed.columnEntities));

            setFeeds(feedEntities);
            props.setSelectedFeed(feedEntities[0]);
        };

        if (feeds.length === 0 || feeds[0].feedName === 'Feed 1') {
            fetchFeeds();
        }
    }, [feeds, props, setFeeds]);
}
