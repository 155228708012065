import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext/UserContext';
import { Form, Button } from 'react-bootstrap';

const SignupForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [,, signup] = useContext(UserContext);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await signup(email, password);
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                        type="email"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                        Sign Up
                    </Button>
                </div>
            </Form>
        </>
    );
};

export default SignupForm;
