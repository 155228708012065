import React, { useState, useContext } from 'react';
import { UserContext } from '../../Context/UserContext/UserContext';
import { Form, Button, Modal } from 'react-bootstrap';
import SignupForm from '../../SignUp/UI/SignUpForm';

const LoginForm = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [, , login,] = useContext(UserContext);
    const [showSignUpModal, setShowSignUpModal] = useState(false);

    const handleShowSignUpModal = () => setShowSignUpModal(true);
    const handleCloseSignUpModal = () => setShowSignUpModal(false);

    const handleSubmit = async (event) => {
        event.preventDefault();
        await login(email, password);
    };

    return (
        <>
            <div className="p-4 box mt-3 text-center">
            </div>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                        type="email"
                        placeholder="Email address"
                        onChange={(e) => setEmail(e.target.value)}
                    />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                        type="password"
                        placeholder="Password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </Form.Group>

                <div className="d-grid gap-2">
                    <Button variant="primary" type="submit">
                        Log In
                    </Button>
                </div>
            </Form>
            <hr/>
            <div>
                {/*<GoogleButton*/}
                {/*    className="g-btn"*/}
                {/*    type="dark"*/}
                {/*    onClick={handleGoogleSignIn}*/}
                {/*/>*/}
            </div>
            <div className="p-4 box mt-3 text-center">
                Don't have an account? <span onClick={handleShowSignUpModal}
                                             style={{cursor: 'pointer', color: 'blue'}}>Sign up</span>
            </div>

            <Modal show={showSignUpModal} onHide={handleCloseSignUpModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <SignupForm/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseSignUpModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default LoginForm;
